import { Howl, Howler } from 'howler'
import Store from '@/store'

let Audio = function(audios) {
  this.audios = audios.map(el => {
    el.howl = null
    return el
  })
  this.index = 0
  this.playState = ''
}

Audio.prototype = {
  play: function(index) {
    let _this = this
    if (!index) index = 0
    let data = this.audios[index]
    let sound
    
    if (data.howl) {
      sound = data.howl
    } else {
      sound = data.howl = new Howl({
        src: data.audioUrl,
        html5: true,
        onplay: function() {
          _this.index = index
          _this.playState = 'start'
        },
        onend: function() {
          _this.playState = 'end'
        },
        onstop: function() {
          _this.playState = 'stop'
        }
      })
    }
    
    Howler.stop()
    sound.rate(Store.state.userSettings.playBackRate)
    sound.play()
  },
  stop: function() {
    let sound = this.audios[this.index].howl
    sound.stop()
  },
  duration: function(index) {
    if (!index) index = 0
    let sound = this.audios[index].howl
    return sound.duration() / Store.state.userSettings.playBackRate
  }
}

export default Audio